import * as React from "react"
import Layout from "../components/layout"
import "@fontsource/raleway/300.css"
import "@fontsource/raleway/500.css"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Hero from '../components/hero'
import { ImageFull } from '../components/imageelements'


// styles
const MainBodySection = styled.article`
  max-width: 960px;
  margin: 0 auto 50px;
  text-align: center;

  > p {
    color: #b13a04;
    max-width: 600px;
    margin: 30px auto 30px auto;
    line-height: 24px
  }
}
`;


// markup
const NotFoundPage = () => {
  return (
    <Layout>
        <Hero title="404"/>
        <MainBodySection>
          <h3>Sorry you've taken a wrong turn. The page you were looking for does not exist!</h3>
          <ImageFull>
            <StaticImage src="../images/operas/tosca/ToscaFalls_MikeHoban_ENO.jpg" alt="Tosca (Amanda Echalaz) falls, English National Opera"/>
          </ImageFull>
        </MainBodySection>
    </Layout>
  )
}

export default NotFoundPage
